import React, { useState } from "react";
import "./restauranthome.css";
import logo from "../uploads/coffeeCaramel.png";
import HomeButton from "../components/HomeButton";
import MenuButton from "../components/MenuButton";

// Icons
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { IoRestaurantOutline } from "react-icons/io5";
import { BiCalendarAlt } from "react-icons/bi";
import HomeFooter from "../components/HomeFooter";
import { useParams } from "react-router-dom";
import { GiCartwheel } from "react-icons/gi";

const RestaurantHome = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [age, setAge] = useState("");
  const [role, setRole] = useState("מלצר");

  const { name } = useParams();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isFormValid = userName !== "" && age !== "";

  return (
    <div className="home-rest-cnt">
      <img src={logo} alt="logo" style={{ width: "70%" }} />

      <div className="buttons-cnt">
        <HomeButton icon={FaFacebook} name={"facebook"} href="" />
        <HomeButton icon={FaInstagram} name={"instagram"} href="" />
        <HomeButton icon={FaPhone} name={"טלפון"} tel="+972532266676" />
      </div>

      <div className="divider" />

      <div style={{}}>
        <MenuButton />
      </div>

      <div className="divider" />

      <div className="buttons-cnt" onClick={openModal}>
        <HomeButton icon={IoRestaurantOutline} name={"מחפשים עבודה?"} />
        <HomeButton icon={GiCartwheel} name={"גלגל המזל"} />
        <HomeButton icon={BiCalendarAlt} name={"להזמנת מקום"} href="" />
      </div>
      <HomeFooter />
      {isModalOpen && (
        <div className="modal-home" onClick={closeModal}>
          <div
            className="modal-content-home"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-home" onClick={closeModal}>
              &times;
            </span>
            <h2>מחפשים עבודה?</h2>
            <form className="modal-form-home">
              <label>שם:</label>
              <input
                type="text"
                name="name"
                required
                onChange={(e) => setUserName(e.target.value)}
              />
              <label>גיל:</label>
              <input
                type="number"
                name="name"
                required
                onChange={(e) => setAge(e.target.value)}
              />
              <label>תפקיד:</label>
              <select onChange={(e) => setRole(e.target.value)}>
                <option value={"מלצר"}>מלצר</option>
                <option value={"ברמן"}>ברמן</option>
                <option value={"מארחת"}>מארחת</option>
                <option value={'אחמ"ש'}>אחמ"ש</option>
              </select>
              <button
                disabled={!isFormValid}
                type="button"
                style={{
                  background: isFormValid ? "" : "gray",
                }}
                onClick={() => (
                  (window.location.href = `https://wa.me/+972532266676?text=היי שמי ${userName}, בגיל ${age}, ואני מעוניין לעבוד אצליכם בתור ${role}`),
                  "_blank"
                )}
              >
                שלח
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantHome;
