import React, { useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi";

import "./meals.css";
import "react-toastify/dist/ReactToastify.css";

export default function Meal({ meal, style, auth, index, available, setMeal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [mealObj, setMealObj] = useState(meal);
  const [quantity, setQuantity] = useState(1);
  const [options, setOptions] = useState(0);
  const [optionsPrice, setOptionsPrice] = useState(0);

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
  });

  const [modalSpring, setModalSpring] = useSpring(() => ({
    opacity: 0,
    config: { duration: 500 },
  }));

  const handleContainerClick = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = cart.find((item) => item.name === mealObj.name);

    if (existingItem) {
      setQuantity(existingItem.quantity);
    } else {
      setQuantity(1);
    }

    setModalSpring({ transform: "translateY(0%)", opacity: 1 });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setOptions(0);
    setOptionsPrice(0);

    setModalSpring({ opacity: 0 });

    setTimeout(() => setIsModalOpen(false), 500); // סגירה לאחר האנימציה
  };

  const addCart = () => {
    setOptions(0);
    setOptionsPrice(0);

    setModalSpring({ transform: "translateY(100%)", opacity: 0 });

    setTimeout(() => setIsModalOpen(false), 500); // סגירה לאחר האנימציה
  };

  const handleEditCheck = () => {
    setEditable(false);
    setMeal(mealObj, index); // עדכון פרטי המנה
  };

  const addToCart = () => {
    const cartItem = {
      name: mealObj.name,
      price: Number(mealObj.price) + optionsPrice,
      quantity,
      options,
    };

    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = existingCart.findIndex(
      (item) => item.name === cartItem.name
    );

    if (existingItemIndex !== -1) {
      existingCart[existingItemIndex].quantity = quantity;
      if (cartItem.options) {
        existingCart.push(cartItem);
      }
    } else {
      existingCart.push(cartItem);
    }

    setOptions(0);
    setOptionsPrice(0);
    localStorage.setItem("cart", JSON.stringify(existingCart));
    addCart();
  };

  return (
    <>
      <animated.div
        style={{ ...style, ...springs }}
        className="meal-cnt"
        onClick={handleContainerClick}
      >
        {auth && (
          <div className="edit-cnt">
            {meal.available ? (
              <IoEye onClick={() => available(index)} />
            ) : (
              <IoEyeOff onClick={() => available(index)} />
            )}
            <FaEdit onClick={() => setEditable(true)} />
            <BsCheckCircleFill onClick={handleEditCheck} />
          </div>
        )}

        <div style={{ width: meal.img ? "230px" : "335px" }}>
          <div className="title-price">
            {editable ? (
              <>
                <input
                  style={{ width: "200px" }}
                  value={mealObj.name}
                  onChange={(e) =>
                    setMealObj({ ...mealObj, name: e.target.value })
                  }
                />
                <input
                  style={{ fontSize: "15px", width: "75px" }}
                  value={mealObj.price}
                  onChange={(e) =>
                    setMealObj({ ...mealObj, price: e.target.value })
                  }
                />
              </>
            ) : (
              <>
                <p style={{ width: "180px" }}>{mealObj.name}</p>
                <p style={{ fontSize: "15px", overflow: "hidden" }}>
                  {mealObj.price}
                </p>
              </>
            )}
          </div>

          <div
            style={{ width: mealObj.img ? "200px" : "220px" }}
            className="desc"
          >
            {editable ? (
              <textarea
                value={mealObj.desc}
                onChange={(e) =>
                  setMealObj({ ...mealObj, desc: e.target.value })
                }
              />
            ) : (
              mealObj.desc
            )}
          </div>
        </div>

        {mealObj.img && (
          <img
            src={mealObj.img}
            className="meals-img"
            onClick={handleContainerClick}
          />
        )}
      </animated.div>

      {isModalOpen && (
        <animated.div style={modalSpring} className="modal">
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {mealObj.img && (
              <img src={mealObj.img} className="modal-img" alt="Meal" />
            )}
            <div className="modal-header">
              <h2>{mealObj.name}</h2>
              <BsFillXCircleFill onClick={closeModal} className="close-btn" />
            </div>
            <div className="modal-body">
              <p style={{ minHeight: "200px" }}>{mealObj.desc}</p>
              <div className="menu-cart-cnt">
                <button onClick={addToCart} className="add-to-cart-btn">
                  הוסף לעגלה
                  <p>{mealObj.price * quantity + optionsPrice} ₪</p>
                </button>
                <div className="quantity-controls">
                  <HiPlusCircle onClick={() => setQuantity(quantity + 1)} />
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) =>
                      setQuantity(Math.max(1, Number(e.target.value)))
                    }
                    min="1"
                    style={{ color: "white" }}
                  />
                  <HiMinusCircle
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                  />
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
}
