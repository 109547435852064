import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./reducers/userReducers.js";
import {
  businessReducer,
  businessRegisterReducer,
  membersReducer,
  menuReducer,
  settingsReducer,
  styleReducer,
} from "./reducers/businessReducer.js";
import { workerReducer, workersReducer } from "./reducers/workerReducer.js";
import { tableReducer, tablesReducer } from "./reducers/tablesReducer.js";

const reducer = combineReducers({
  user: userReducer,
  business: businessReducer,
  businessRegister: businessRegisterReducer,
  menu: menuReducer,
  settings: settingsReducer,
  style: styleReducer,
  members: membersReducer,
  workers: workersReducer,
  worker: workerReducer,
  tables: tablesReducer,
  table: tableReducer,
});
const userFromStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const businessFromStorage = localStorage.getItem("businessInfo")
  ? JSON.parse(localStorage.getItem("businessInfo"))
  : null;

const menuFromStorage = localStorage.getItem("menu")
  ? JSON.parse(localStorage.getItem("menu"))
  : null;

const styleFromStorage = localStorage.getItem("style")
  ? JSON.parse(localStorage.getItem("style"))
  : null;

const membersFromStorage = localStorage.getItem("members")
  ? JSON.parse(localStorage.getItem("members"))
  : { loading: false };

const tablesInfoFromStorage = localStorage.getItem("tables")
  ? JSON.parse(localStorage.getItem("tables"))
  : null;

const workersInfoFromStorage = localStorage.getItem("workers")
  ? JSON.parse(localStorage.getItem("workers"))
  : null;

const workerInfoFromStorage = localStorage.getItem("worker")
  ? JSON.parse(localStorage.getItem("worker"))
  : null;

const tableFromStorage = localStorage.getItem("table")
  ? JSON.parse(localStorage.getItem("table"))
  : null;

const initialState = {
  business: { info: businessFromStorage },
  menu: menuFromStorage,
  style: styleFromStorage,
  members: membersFromStorage,
  tables: tablesInfoFromStorage,
  workers: workersInfoFromStorage,
  worker: workerInfoFromStorage,
  user: userFromStorage,
  table: tableFromStorage,
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
