import axios from "axios";
import {
  BUSINESS_LOGIN_REQUEST,
  BUSINESS_LOGIN_SUCCESS,
  BUSINESS_LOGIN_FAIL,
  BUSINESS_REGISTER_REQUEST,
  BUSINESS_REGISTER_SUCCESS,
  BUSINESS_REGISTER_FAIL,
  BUSINESS_ADD_CATEGORY_REQUEST,
  BUSINESS_ADD_CATEGORY_SUCCESS,
  BUSINESS_ADD_CATEGORY_FAIL,
  BUSINESS_ADD_MEAL_REQUEST,
  BUSINESS_ADD_MEAL_SUCCESS,
  BUSINESS_ADD_MEAL_FAIL,
  BUSINESS_LOGOUT,
  BUSINESS_GET_MENU_SUCCESS,
  BUSINESS_EDIT_MENU_REQUEST,
  BUSINESS_EDIT_MENU_SUCCESS,
  BUSINESS_EDIT_MENU_FAIL,
  BUSINESS_POST_MENU_REQUEST,
  BUSINESS_POST_MENU_SUCCESS,
  BUSINESS_POST_MENU_FAIL,
} from "../constants/businessConstant";
import {
  BUSINESS_MEMBERS_SUCCESS,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";
import {
  GET_WORKER_FAIL,
  GET_WORKER_REQUEST,
  GET_WORKER_SUCCESS,
  WORKER_ADD_FAIL,
  WORKER_ADD_REQUEST,
  WORKER_ADD_SUCCESS,
  WORKER_GET_LIST_FAIL,
  WORKER_GET_LIST_REQUEST,
  WORKER_GET_LIST_SUCCESS,
  WORKER_LOGIN_FAIL,
  WORKER_LOGIN_REQUEST,
  WORKER_LOGIN_SUCCESS,
} from "../constants/workerConstants";
import {
  ASSIGN_WAITER_FAIL,
  ASSIGN_WAITER_REQUEST,
  ASSIGN_WAITER_SUCCESS,
  HOSTESS_UPDATE_TABLE_FAIL,
  HOSTESS_UPDATE_TABLE_REQUEST,
  HOSTESS_UPDATE_TABLE_SUCCESS,
  MOVE_TABLE_FAIL,
  MOVE_TABLE_REQUEST,
  MOVE_TABLE_SUCCESS,
  TABLE_ADD_FAIL,
  TABLE_ADD_REQUEST,
  TABLE_ADD_SUCCESS,
  TABLES_GET_LIST_FAIL,
  TABLES_GET_LIST_REQUEST,
  TABLES_GET_LIST_SUCCESS,
  TABLES_UPDATE_FAIL,
  TABLES_UPDATE_REQUEST,
  TABLES_UPDATE_SUCCESS,
} from "../constants/tablesConstant";
import {
  USER_ACCEPT_TABLE_FAIL,
  USER_ACCEPT_TABLE_REQUEST,
  USER_ACCEPT_TABLE_SUCCESS,
} from "../constants/userConstant";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: BUSINESS_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/business/auth",
      { email, password },
      config
    );

    dispatch({ type: BUSINESS_LOGIN_SUCCESS, payload: data.auth });
    dispatch({ type: BUSINESS_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_MEMBERS_SUCCESS, payload: data.members });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
    dispatch({ type: TABLES_GET_LIST_SUCCESS, payload: data.tables });
    dispatch({ type: WORKER_GET_LIST_SUCCESS, payload: data.workers });

    localStorage.setItem("businessInfo", JSON.stringify(data.auth));
    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("members", JSON.stringify(data.members));
    localStorage.setItem("style", JSON.stringify(data.style));
    localStorage.setItem("tables", JSON.stringify(data.tables));
    localStorage.setItem("workers", JSON.stringify(data.workers));
  } catch (error) {
    dispatch({
      type: BUSINESS_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const workerLogin =
  (business, userName, password) => async (dispatch) => {
    try {
      dispatch({ type: WORKER_LOGIN_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "/api/business/worker/auth",
        { business, userName, password },
        config
      );

      localStorage.setItem("tables", JSON.stringify(data.tables));
      localStorage.setItem("worker", JSON.stringify(data.workerInfo));
      localStorage.setItem("businessName", business);

      dispatch({ type: WORKER_LOGIN_SUCCESS, payload: data.workerInfo });
      dispatch({ type: TABLES_GET_LIST_SUCCESS, payload: data.tables });
    } catch (error) {
      dispatch({
        type: WORKER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCategory = (obj) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_CATEGORY_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/category", obj, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_ADD_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMeal = (meal) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_MEAL_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/meal", { meal }, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_ADD_MEAL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_MEAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerBusiness = (email, name, password) => async (dispatch) => {
  try {
    dispatch({ type: BUSINESS_REGISTER_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/business/",
      { email, name, password },
      config
    );

    localStorage.setItem("businessInfo", JSON.stringify(data));
    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("style", JSON.stringify(data.style));

    dispatch({ type: BUSINESS_REGISTER_SUCCESS, payload: data });
    dispatch({ type: BUSINESS_LOGIN_SUCCESS, payload: data });
    dispatch({ type: BUSINESS_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
  } catch (error) {
    dispatch({
      type: BUSINESS_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  Object.keys(localStorage).forEach((key) => {
    if (
      [
        "user",
        "businessInfo",
        "menu",
        "worker",
        "tables",
        "workers",
        "style",
        "members",
      ].includes(key)
    ) {
      localStorage.removeItem(key);
    }
  });

  dispatch({ type: BUSINESS_LOGOUT });
};

export const workerLogout = () => async (dispatch, getState) => {
  try {
    const {
      worker: { token },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    await axios.post("/api/business/worker/logout", config);

    Object.keys(localStorage).forEach((key) => {
      if (
        ["user", "business", "menu", "worker", "tables", "workers"].includes(
          key
        )
      ) {
        localStorage.removeItem(key);
      }
    });

    dispatch({ type: BUSINESS_LOGOUT });
  } catch (error) {
    dispatch({
      type: BUSINESS_LOGOUT,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const businessInfo = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_ADD_CATEGORY_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.get("/api/business", config);

    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("style", JSON.stringify(data.style));
    localStorage.setItem("members", JSON.stringify(data.members));

    dispatch({ type: BUSINESS_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
    dispatch({ type: BUSINESS_MEMBERS_SUCCESS, payload: data.members });
  } catch (error) {
    dispatch({
      type: BUSINESS_ADD_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editMenuAction = (menu) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_EDIT_MENU_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.put("/api/business/menu", { menu }, config);

    localStorage.setItem("menu", JSON.stringify(data));
    dispatch({ type: BUSINESS_EDIT_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_EDIT_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postMenuAction = (menu) => async (dispatch, getState) => {
  try {
    dispatch({ type: BUSINESS_POST_MENU_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post("/api/business/menu", { menu }, config);

    dispatch({ type: BUSINESS_POST_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BUSINESS_POST_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addNewWorker = (newWorker) => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKER_ADD_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };

    const { data } = await axios.post(
      "/api/business/worker",
      newWorker,
      config
    );

    dispatch({ type: WORKER_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WORKER_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWorkers = (token) => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKER_GET_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get("/api/business/workers", config);

    localStorage.setItem("workers", JSON.stringify(data));

    dispatch({ type: WORKER_GET_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: WORKER_GET_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const hostessUpdateTable =
  (updateTable) => async (dispatch, getState) => {
    try {
      dispatch({ type: HOSTESS_UPDATE_TABLE_REQUEST });

      const {
        worker: { token },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.put(
        "/api/business/hostess",
        updateTable,
        config
      );

      localStorage.setItem("tables", JSON.stringify(data));

      dispatch({ type: HOSTESS_UPDATE_TABLE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: HOSTESS_UPDATE_TABLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const acceptGuest = (guest, table) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ACCEPT_TABLE_REQUEST });

    const {
      worker: { token },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(
      "/api/business/waiter/guest",
      { guest, table },
      config
    );

    localStorage.setItem("tables", JSON.stringify(data.tables));

    dispatch({ type: TABLES_UPDATE_SUCCESS, payload: data.tables });
  } catch (error) {
    dispatch({
      type: USER_ACCEPT_TABLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTables = (token) => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLES_GET_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(`/api/business/waiter`, config);

    localStorage.setItem("tables", JSON.stringify(data));

    dispatch({
      type: TABLES_GET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TABLES_GET_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWorker = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_WORKER_REQUEST });

    let token;

    // קבלת הטוקן מה-state
    const { worker } = getState();
    token = worker.token; // הגדרת הטוקן מה-state

    // אם הטוקן לא קיים, חפש אותו ב-localStorage
    if (!token) {
      const workerFromLocalStorage = JSON.parse(localStorage.getItem("worker"));
      token = workerFromLocalStorage ? workerFromLocalStorage.token : null;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(`/api/business/worker`, config);

    localStorage.setItem("worker", JSON.stringify(data));

    dispatch({
      type: GET_WORKER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WORKER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addTable = (tableId, capacity) => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLE_ADD_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/business/table`,
      { tableId, capacity },
      config
    );

    localStorage.setItem("tables", JSON.stringify(data));

    dispatch({
      type: TABLE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TABLE_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const moveTable = (tableId, position) => async (dispatch, getState) => {
  try {
    dispatch({ type: MOVE_TABLE_REQUEST });

    const {
      business: { info },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${info.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/business/movetable`,
      { tableId, position },
      config
    );

    localStorage.setItem("tables", JSON.stringify(data));

    dispatch({
      type: MOVE_TABLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MOVE_TABLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const waiterAssignment =
  (tableId, name) => async (dispatch, getState) => {
    try {
      dispatch({ type: ASSIGN_WAITER_REQUEST });

      const {
        worker: { token },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.post(
        `/api/business/waiterassignment`,
        { tableId, name },
        config
      );

      localStorage.setItem("tables", JSON.stringify(data));

      dispatch({
        type: ASSIGN_WAITER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_WAITER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const closeTable = (tableId) => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLES_UPDATE_REQUEST });

    const {
      worker: { token },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      `/api/business/table`,
      { tableId },
      config
    );

    console.log(data);

    localStorage.setItem("tables", JSON.stringify(data));

    dispatch({
      type: TABLES_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TABLES_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
