import React, { useEffect, useState } from "react";
import { login } from "../actions/businessAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import blueVelvet from "../uploads/dark-wood.jpg";
import BackgroundImage from "../components/BackgroundImage";
import "./login.css";
import Loader from "../components/Loader";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();

  const business = useSelector((state) => state.business);
  const { error, loading } = business;
  const { info } = business;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  useEffect(() => {
    if (info && info.token) {
      navigate("/settings");
    } else {
      navigate("/login");
    }
  }, [navigate, info]);

  return (
    <div className="login-cnt">
      <BackgroundImage img={blueVelvet} />
      <Logo />
      {loading ? (
        <Loader cnt={{ height: "200px", color: "wheat" }} />
      ) : (
        <>
          <div className="login-tab">
            <h1>התחברות לדף העסק</h1>
            <h2>שם משתמש</h2>
            {error && <span className="error">{error}</span>}
            <input
              placeholder="הזן כאן"
              onChange={(e) => setEmail(e.target.value)}
            />
            <h2>סיסמה</h2>
            <input
              type="password"
              placeholder="הזן כאן"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              style={{ width: "100px", height: "30px" }}
              onClick={(e) => submitHandler(e)}
            >
              היכנס!
            </button>
          </div>
          <button
            style={{ marginTop: "30px" }}
            onClick={() => navigate("/register-business")}
          >
            להרשמה לחץ כאן
          </button>
        </>
      )}
    </div>
  );
}
