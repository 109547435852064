import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { purchase } from "../actions/userActions";
import Loader from "../components/Loader";
import "./Purchase.css";
// import visa from "../uploads/visa.png";
// import applePay from "../uploads/apple.png";
// import bit from "../uploads/bit.png";
// import googlePay from "../uploads/google-pay.png";

export default function Purchase() {
  const [purchases, setPurchases] = useState([]);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    auth: authState,
    name: nameState,
    loading,
  } = useSelector((state) => state.user || "");

  const [name, setName] = useState(nameState);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tip, setTip] = useState(0);
  const [focusedImage, setFocusedImage] = useState(null); // Track the focused

  const handleClick = (imageName) => {
    setFocusedImage(imageName); // Update the focused image
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      purchase({
        items: purchases,
        name: user.name,
        phone: user.phone,
        amount: totalAmount,
        tip,
      })
    ).then(() => {
      // פתח את הקישור לאחר השלמת הרכישה
      if (user.link) {
        window.open(user.link, "_blank"); // 'window.open' יפתח את הקישור בחלון חדש
      } else {
        console.log(user.link);
      }
    });
  };

  useEffect(() => {
    // Retrieve data from localStorage and parse it
    const storedPurchases = JSON.parse(localStorage.getItem("purchase"));
    if (storedPurchases) {
      setPurchases(storedPurchases);
    }

    // Calculate total payment
    const totalPayment = storedPurchases.reduce(
      (sum, purchase) => sum + purchase.price * purchase.quantity,
      0
    );

    setTotalAmount(totalPayment);
  }, []);

  useEffect(() => {
    if (user.link) {
      window.open(user.link, "_blank");
    }
  }, [user.link]);

  if (user.loading) {
    return <Loader />;
  }

  return (
    <div className="checkout-container" onSubmit={handleSubmit}>
      <h1 className="checkout-title">חשבון</h1>

      <form className="checkout-form">
        {/* Order Summary */}
        <div className="order-summary">
          <h2 style={{ padding: "10px 15px" }}>סיכום</h2>
          <table className="summary-table">
            <thead>
              <tr>
                <th>מוצר</th>
                <th>מחיר</th>
                <th>כמות</th>
                <th>סה"כ</th>
              </tr>
            </thead>
            <tbody>
              {purchases.map((item, index) => (
                <>
                  <tr key={index}>
                    <td>
                      <strong>{item.name}</strong>
                    </td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.quantity * item.price}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">סה"כ לתשלום</td>
                <td colSpan="2" style={{ textAlign: "left" }}>
                  {totalAmount} ₪
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h5>תרצו להוסיף טיפ?</h5>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              alignItems: "center",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setTip(Math.ceil(totalAmount * 0.1));
              }}
            >
              10%
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTip(Math.ceil(totalAmount * 0.12));
              }}
            >
              12%
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTip(Math.ceil(totalAmount * 0.15));
              }}
            >
              15%
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTip(Math.ceil(totalAmount * 0.2));
              }}
            >
              20%
            </button>
            <input
              type="number"
              min={0}
              value={tip}
              onChange={(e) => setTip(Math.max(0, e.target.value))}
              style={{
                width: "50px",
                backgroundColor: "white",
                color: "black",
                width: "70px",
                margin: "0",
              }}
            />
          </div>
        </div>
        {/* <div
          className="payment-method-cnt"
          style={{ display: "flex", gap: "10px" }}
        >
          <img
            className={`payment-method ${
              focusedImage === "visa" ? "focused" : ""
            }`}
            alt="visa"
            src={visa}
            onClick={() => handleClick("visa")}
            style={{ cursor: "pointer" }}
          />
          <img
            className={`payment-method ${
              focusedImage === "bit" ? "focused" : ""
            }`}
            alt="bit"
            src={bit}
            onClick={() => handleClick("bit")}
            style={{ cursor: "pointer" }}
          />
          <img
            className={`payment-method ${
              focusedImage === "applePay" ? "focused" : ""
            }`}
            alt="apple-pay"
            src={applePay}
            onClick={() => handleClick("applePay")}
            style={{ cursor: "pointer" }}
          />
          <img
            className={`payment-method ${
              focusedImage === "googlePay" ? "focused" : ""
            }`}
            alt="google-pay"
            src={googlePay}
            onClick={() => handleClick("googlePay")}
            style={{ cursor: "pointer" }}
          />
        </div> */}

        <button type="submit" className="checkout-button">
          סה"כ {totalAmount + Number(tip)} לתשלום
        </button>
      </form>
    </div>
  );
}
