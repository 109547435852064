import { BUSINESS_LOGOUT } from "../constants/businessConstant";
import {
  USER_ACCEPT_TABLE_FAIL,
  USER_ACCEPT_TABLE_REQUEST,
  USER_ACCEPT_TABLE_SUCCESS,
  USER_CALL_TO_WAITER_FAIL,
  USER_CALL_TO_WAITER_REQUEST,
  USER_CALL_TO_WAITER_SUCCESS,
  USER_ENTER_TABLE_FAIL,
  USER_ENTER_TABLE_REQUEST,
  USER_ENTER_TABLE_SUCCESS,
  USER_GET_MENU_FAIL,
  USER_GET_MENU_REQUEST,
  USER_GET_MENU_SUCCESS,
  USER_LOGOUT,
  USER_PLACE_ORDER_FAIL,
  USER_PLACE_ORDER_REQUEST,
  USER_PLACE_ORDER_SUCCESS,
  USER_PURCHASE_FAIL,
  USER_PURCHASE_REQUEST,
  USER_PURCHASE_SUCCESS,
} from "../constants/userConstant";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ENTER_TABLE_REQUEST:
      return { loading: true };
    case USER_ENTER_TABLE_SUCCESS:
      return { loading: false, ...action.payload };
    case USER_ENTER_TABLE_FAIL:
      return { loading: false, error: action.payload };
    case USER_ACCEPT_TABLE_REQUEST:
      return { loading: true };
    case USER_ACCEPT_TABLE_SUCCESS:
      return { loading: false, ...action.payload };
    case USER_ACCEPT_TABLE_FAIL:
      return { loading: false, error: action.payload };
    case USER_PLACE_ORDER_REQUEST:
      return { ...state, loading: true };
    case USER_PLACE_ORDER_SUCCESS:
      return { ...state, loading: false, lastOrder: "success" };
    case USER_PLACE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastOrder: "fail",
      };
    case USER_CALL_TO_WAITER_REQUEST:
      return { ...state, loading: true };
    case USER_CALL_TO_WAITER_SUCCESS:
      return { ...state, loading: false, msg: action.payload };
    case USER_CALL_TO_WAITER_FAIL:
      return { ...state, loading: false, msg: action.payload };
    case USER_PURCHASE_REQUEST:
      return { ...state, loading: true };
    case USER_PURCHASE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case USER_PURCHASE_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    case BUSINESS_LOGOUT:
      return {};
    default:
      return state;
  }
};
