import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";

const data = [
  { option: "משהו 5" },
  { option: "2" },
  { option: "3" },
  { option: "משהו" },
  { option: "4" },
  { option: "משהו 2" },
];

export default () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  return (
    <>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        onStopSpinning={() => {
          setMustSpin(false);
        }}
      />
      {prizeNumber}
      <button onClick={handleSpinClick}>SPIN</button>
    </>
  );
};
