import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import Loader from "../components/Loader";
import TablesManager from "../components/settings/TablesManager";
import GeneralSettings from "../components/settings/GeneralSettings";
import MenuProManager from "../components/MenuProManager";
import Members from "../components/Members";
import { getMenu } from "../actions/userActions";
import { BiBookOpen } from "react-icons/bi";
import RolesManager from "../components/RolesManager";
import Dashboard from "../components/Dashboard";
import RestaurantSettings from "../components/RestaurantSettings";
import { getTables, getWorkers } from "../actions/businessAction";
import StyleSettings from "../components/StyleSettings";
import logo from "../uploads/logo3.png";
import BackgroundImage from "../components/BackgroundImage";

export default function SettingsPro() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const business = useSelector((state) => state.business);
  const { info } = business;

  const menu = useSelector((state) => state.menu) || {};
  const { loading } = menu;

  const style = useSelector((state) => state.style) || {};
  const { background, parentButton, home, font, loading: styleLoading } = style;

  const settings = useSelector((state) => state.settings) || {};

  const [type, setType] = useState("menu");

  const renderActiveComponent = () => {
    switch (type) {
      case "menu":
        return <MenuProManager style={style} />;
      case "tables":
        return <TablesManager />;
      case "fans":
        return <Members />;
      case "Roles":
        return <RolesManager />;
      case "general":
        return <GeneralSettings />;
      case "style":
        return <StyleSettings />;
      case "settings":
        return <RestaurantSettings />;
      case "dashboard":
        return <Dashboard />;

      default:
        return <TablesManager />;
    }
  };

  useEffect(() => {
    if (!business?.info?.token) {
      navigate("/login");
    } else {
      dispatch(getMenu(business.info.name));
    }
  }, []);

  if (styleLoading || loading || !business?.info?.token) {
    return <Loader />;
  }

  if (info.permissions == "menu") {
    return (
      <div
        style={{
          background: background.type === "color" ? background.url : "none",
          minHeight: "100vh",
          ...font,
          paddingBottom: "20px",
        }}
      >
        <BackgroundImage img={background.url} />

        <BiBookOpen
          className="home-btn"
          style={home}
          onClick={() => navigate(`/${info.name}/menu2`)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <img src={style.logo.url} style={{ width: "40%" }} /> */}
          <img
            src={logo}
            alt={logo}
            style={{ height: "150px", width: "150px" }}
          />
        </div>
        <div className="settings-btn-cnt">
          <button style={parentButton.style} onClick={() => setType("menu")}>
            הגדרות תפריט
          </button>
          <button style={parentButton.style} onClick={() => setType("style")}>
            עיצוב
          </button>
          <button style={parentButton.style} onClick={() => setType("fans")}>
            חברי מועדון
          </button>
          <button
            style={parentButton.style}
            onClick={() => navigate(`/${info.name}/menu`)}
          >
            לתפריט
          </button>
        </div>
        {renderActiveComponent()}
      </div>
    );
  }

  return (
    <div
      style={{
        background: background.type === "color" ? background.url : "none",

        minHeight: "100vh",
        ...font,
        paddingBottom: "20px",
      }}
    >
      {background.type === "image" ? (
        <BackgroundImage img={background.url} />
      ) : (
        ""
      )}
      <BiBookOpen
        className="home-btn"
        style={{
          background: parentButton.style.background,
          color: parentButton.style.color,
        }}
        onClick={() => navigate(`/${info.name}/menu`)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <img src={style.logo.url} style={style.logo.style} />
      </div>
      <div className="settings-btn-cnt">
        <button style={parentButton.style} onClick={() => setType("dashboard")}>
          ראשי
        </button>
        <button style={parentButton.style} onClick={() => setType("menu")}>
          הגדרות תפריט
        </button>

        <button style={parentButton.style} onClick={() => setType("tables")}>
          שולחנות
        </button>
        <button style={parentButton.style} onClick={() => setType("Roles")}>
          הגדרת תפקידים
        </button>
        <button style={parentButton.style} onClick={() => setType("tables")}>
          עיצוב
        </button>
        <button style={parentButton.style} onClick={() => setType("fans")}>
          חברי מועדון
        </button>
        <button
          style={parentButton.style}
          onClick={() => navigate(`/${info.name}/menu`)}
        >
          לתפריט
        </button>
        <button style={parentButton.style} onClick={() => setType("settings")}>
          הגדרות
        </button>
      </div>
      {renderActiveComponent()}
    </div>
  );
}
