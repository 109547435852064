import axios from "axios";
import {
  USER_ACCEPT_TABLE_SUCCESS,
  USER_CALL_TO_WAITER_FAIL,
  USER_CALL_TO_WAITER_REQUEST,
  USER_CALL_TO_WAITER_SUCCESS,
  USER_ENTER_TABLE_FAIL,
  USER_ENTER_TABLE_REQUEST,
  USER_ENTER_TABLE_SUCCESS,
  USER_GET_MENU_FAIL,
  USER_GET_MENU_REQUEST,
  USER_GET_MENU_SUCCESS,
  USER_PLACE_ORDER_FAIL,
  USER_PLACE_ORDER_REQUEST,
  USER_PLACE_ORDER_SUCCESS,
  USER_POST_MEMBERS_REQUEST,
  USER_PURCHASE_FAIL,
  USER_PURCHASE_REQUEST,
  USER_PURCHASE_SUCCESS,
} from "../constants/userConstant";
import {
  BUSINESS_EDIT_MENU_SUCCESS,
  BUSINESS_POST_MENU_SUCCESS,
} from "../constants/businessConstant";
import {
  BUSINESS_MEMBERS_SUCCESS,
  BUSINESS_STYLE_SUCCESS,
} from "../constants/settingsConstant";
import {
  TABLE_GET_FAIL,
  TABLE_GET_REQUEST,
  TABLE_GET_SUCCESS,
} from "../constants/tablesConstant";
import {
  WORKER_ADD_SUCCESS,
  WORKER_GET_LIST_SUCCESS,
} from "../constants/workerConstants";

export const getMenu = (name) => async (dispatch) => {
  try {
    dispatch({ type: USER_GET_MENU_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`/api/users/menu/${name}`, config);

    localStorage.setItem("menu", JSON.stringify(data.menu));
    localStorage.setItem("style", JSON.stringify(data.style));

    dispatch({ type: USER_GET_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_POST_MENU_SUCCESS, payload: data.menu });
    dispatch({ type: BUSINESS_STYLE_SUCCESS, payload: data.style });
  } catch (error) {
    dispatch({
      type: USER_GET_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTable = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TABLE_GET_REQUEST });

    const {
      user: { auth },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth,
      },
    };
    const { data } = await axios.get(`/api/users/table`, config);

    dispatch({ type: TABLE_GET_SUCCESS, payload: data.table });
    dispatch({ type: USER_ACCEPT_TABLE_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({
      type: TABLE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addMember = (name, member) => async (dispatch) => {
  try {
    dispatch({ type: USER_POST_MEMBERS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `/api/users/members/${name}`,
      { member },
      config
    );

    dispatch({
      type: BUSINESS_MEMBERS_SUCCESS,
      payload: "המשתמש הוסף בהצלחה!",
    });
  } catch (error) {
    dispatch({
      type: USER_GET_MENU_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const enterTable =
  (name, phone, tableId, business) => async (dispatch) => {
    try {
      dispatch({ type: USER_ENTER_TABLE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/users/entertable`,
        { name, phone, tableId, business },
        config
      );

      localStorage.setItem("user", JSON.stringify(data.userExist));

      dispatch({
        type: USER_ENTER_TABLE_SUCCESS,
        payload: data.userExist,
      });
    } catch (error) {
      dispatch({
        type: USER_ENTER_TABLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const placeOrder =
  (name, auth, tableId, order, extraRequest) => async (dispatch) => {
    try {
      dispatch({ type: USER_PLACE_ORDER_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      };
      const { data } = await axios.post(
        `/api/users/placeorder`,
        { name, tableId, order, extraRequest },
        config
      );

      dispatch({
        type: USER_PLACE_ORDER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: USER_PLACE_ORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      throw error;
    }
  };

export const callToWaiter = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_CALL_TO_WAITER_REQUEST });

    const {
      user: { auth },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    };

    const { data } = await axios.post(`/api/users/calltowaiter`, {}, config);

    dispatch({
      type: USER_CALL_TO_WAITER_SUCCESS,
      data,
    });
  } catch (error) {
    dispatch({
      type: USER_CALL_TO_WAITER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const purchase =
  ({ items, phone, name, amount, tip }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_PURCHASE_REQUEST });

      const {
        user: { auth },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      };

      const { data } = await axios.post(
        `/api/users/purchase`,
        { items, phone, name, amount, tip },
        config
      );

      dispatch({
        type: USER_PURCHASE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PURCHASE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      throw error;
    }
  };
