import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import BackgroundImage from "../components/BackgroundImage";

const data = [
  { option: "משהו 5" },
  { option: "2" },
  { option: "3" },
  { option: "משהו" },
  { option: "4" },
  { option: "משהו 2" },
];

export default () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  return (
    <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "space-around",
    //     alignItems: "center",
    //     height: "100vh",
    //     flexDirection: "column",
    //   }}
    >
      <BackgroundImage />
      <h3 style={{ color: "white" }}>כל סיבוב זוכה!</h3>
      <div className="roulette-container">
        <Wheel
          spinDuration={[0.2]}
          outerBorderColor={["#ccc"]}
          outerBorderWidth={[9]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["tranparent"]}
          radiusLineWidth={[1]}
          textColors={["#f5f5f5"]}
          textDistance={55}
          fontSize={[10]}
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          onStopSpinning={() => {
            setMustSpin(false);
          }}
        />
      </div>
      <button onClick={handleSpinClick}>סובב וזכה</button>
    </div>
  );
};
